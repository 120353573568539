import React from "react";
import dean from "../images/dean.jpeg";
export default function About() {
  return (
    <div className="about-wrapper">
      <div className="left-content"></div>
      <div className="right-content">
        <p>
          Sapo’nuk Sustainability is an Indigenous-owned and managed consulting
          firm, launched in summer 2022. Our team has extensive experience in
          sustainability, climate change, and environmental impact assessments,
          in the energy, mining, infrastructure, commercial, public, and finance
          sectors. We also have established relationships with other environment
          and sustainability experts so that we can compile the right team to
          serve your needs.
        </p>
        <p>Our Founder and Principal consultant is Dean O’Gorman.</p>
        <p>
          <img src={dean} className="profile-image" />
          Dean is a creative problem-solver who brings 25 years of increasingly
          senior experience in both the public sector and the consulting world.
          Dean is Mi’kmaq, a member of Qalipu First Nation, and is passionate
          about integrating environmental sustainability with Indigenous
          reconciliation and self-determination.
        </p>
        <p title="next">
          As a former Hearing Commissioner at the Alberta Energy Regulator and
          executive at the federal Impact Assessment Agency, along with his
          consulting experience, Dean is an expert in environmental impact
          assessment and has worked on a wide range of sustainability issues,
          such as water, biodiversity, air pollution, human health, stakeholder
          engagement, and Indigenous rights and consultation. Dean was part of
          the federal-provincial review panel that rejected the first new
          proposed coal mine on the eastern slopes of the Rockies (Grassy
          Mountain).
        </p>
        <p>
          As a former senior climate change policy expert at Environment Canada,
          Dean has a deep technical skill set in areas such as GHG emissions
          inventories and reporting, decarbonization strategies and policy
          design, science-based targets, techno-economic modeling, nature-based
          solutions and offsets, adaptation measures, and carbon
          pricing/markets.
        </p>
      </div>
    </div>
  );
}
