import React, { useState, useEffect } from "react";
import { Link, animateScroll as scroll, scroller } from "react-scroll";
import { animated, useSpring } from "react-spring";
import classNames from "classnames";
import downArrow from "../images/down-arrow.svg";
import { DownArrow } from "../images/svg/DownArrow";
import boxArrow from "../images/svg/box-arrow.svg";
import backgroundShape from "../images/svg/background-shape.svg";
import overlay from "../images/service-icons/overlay.png";
// "m 240.60082,535.73219 c -52.98236,18.65623 6.34237,45.5224 7.85983,124.85399 0.0704,60.66907 126.98594,124.74118 114.28366,48.33147 -12.70229,-76.4097 -69.16114,-191.84168 -122.14349,-173.18546 z",

const paths = [
  "m 211.64663,538.62761 c -55.91537,5.35323 35.29656,42.62698 36.81402,121.95857 0.0704,60.66907 126.98594,124.74118 114.28366,48.33147 -12.70229,-76.4097 -92.32449,-175.91687 -151.09768,-170.29004 z",
  "m 276.65027,536.4685 c -37.67567,1.82938 -46.22974,54.35814 -28.18962,124.11768 26.35645,68.03218 137.6791,122.27352 114.28366,48.33147 -26.97186,-85.24544 -47.7055,-175.74379 -86.09404,-172.44915 z",
  "m 333.25164,532.96649 c -37.67567,6.32999 -84.9256,75.25364 -84.79099,127.61969 0.76182,64.12619 126.98594,124.74118 114.28366,48.33147 -12.70229,-76.4097 0.55571,-180.99966 -29.49267,-175.95116 z",
  "m 376.11993,531.58364 c -37.67567,6.32999 -127.79389,76.63649 -127.65928,129.00254 0.76182,64.12619 66.85612,109.57215 114.28366,48.33147 16.33752,-21.09578 43.424,-182.38251 13.37562,-177.33401 z",
];

export default function Home(props) {
  const [hoverColor, setHoverColor] = useState("#90ce84");
  const [currentService, setCurrentService] = useState("indigenous");
  const [activeIndex, setActiveIndex] = useState(0);
  const animationProps = useSpring({
    path: paths[activeIndex],
    config: { mass: 10, tension: 400, friction: 90, precision: 0.00001 },
  });

  function setHover(color) {
    setHoverColor(color);
  }
  useEffect(() => {
    setActiveIndex(
      currentService === "indigenous"
        ? 0
        : currentService === "sust"
        ? 1
        : currentService === "decarb"
        ? 2
        : 3
    );
  }, [currentService]);

  return (
    <div style={{ position: "absolute" }}>
      <div className="hero-image" title="hero-image">
        <div className="tagline">
          <p>Create a sustainable tomorrow.</p>
          <p>Navigate the Net Zero transition. </p>
          <p>
            Deliver projects that benefit Indigenous communities and nature.
          </p>
        </div>

        <div
          className="down-arrow"
          onMouseEnter={() => setHover("#90ce84")}
          onMouseLeave={() => setHover("#7EB573")}
        >
          {" "}
          <Link to="services" smooth={false} duration={600}>
            <DownArrow fillColor={hoverColor} height="70px" />
          </Link>
        </div>
      </div>
      <section className="services" title="services">
        <div className="service-background">
          {/* <img src={backgroundShape} /> */}
          <svg
            width="105.18627mm"
            height="102.08012mm"
            viewBox="0 0 105.18627 102.08012"
            version="1.1"
            id="svg1347"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs id="defs1344" />
            <g id="layer1" transform="translate(-44.30998,-137.17437)">
              <animated.path
                id="path3206"
                style={{
                  color: "#000000",
                  fill: "#5daf00",
                  fillOpacity: 0.179601,
                  strokeWidth: "0.999999px",
                }}
                d={animationProps.path}
                transform="scale(0.26458333)"
              />
            </g>
          </svg>
        </div>
        <div className="service-headings">
          <div
            className="service "
            onClick={() => setCurrentService("indigenous")}
          >
            <div className="service-heading service-heading-indigenous"></div>
            <span>Supporting Indigenous Leadership</span>
          </div>
          <div className="service" onClick={() => setCurrentService("sust")}>
            <div
              className={classNames(
                "service-heading service-heading-sustainability"
              )}
            ></div>
            <span>Sustainability</span>
          </div>
          <div className="service" onClick={() => setCurrentService("decarb")}>
            <div className="service-heading service-heading-decarbonization"></div>
            <span>Decarbonization</span>
          </div>
          <div className="service " onClick={() => setCurrentService("impact")}>
            <div className="service-heading service-heading-impact"></div>
            <span>Environmental Impact Assessments</span>
          </div>
        </div>
        <div className="service-text">
          <img
            src={boxArrow}
            className={`service-text-arrow service-${currentService}`}
          />
          <div className="service-text-descriptions">
            <div
              className={`service-text-description ${
                currentService === "indigenous" ? "desc-visible" : "desc-hidden"
              }`}
            >
              <div>
                <p>-</p>
                <p>
                  Climate and sustainability policy and strategy development,
                  including engagement with government and industry.
                </p>
              </div>
              <div>
                <p>-</p>
                <p>
                  Advance projects leading to a clean energy future, such as
                  renewables, hydrogen, energy efficiency, and GHG offsets.
                </p>
              </div>
              <div>
                <p>-</p>
                <p>
                  {" "}
                  Access federal and provincial funding opportunities related to
                  climate change, environment, and clean energy.
                </p>
              </div>
              <div>
                <p>-</p>
                <p>Adaptation strategies and vulnerability assessments.</p>
              </div>
            </div>
            <div
              className={`service-text-description ${
                currentService === "sust" ? "desc-visible" : "desc-hidden"
              }`}
            >
              <div>
                <p>-</p>
                <p>
                  Assess your material Environmental, Social, and Governance
                  (ESG) risks and impacts.
                </p>
              </div>
              <div>
                <p>-</p>
                <p>
                  Integrate Indigenous rights and interests into corporate
                  sustainability/ESG plans and actions.
                </p>
              </div>
              <div>
                <p>-</p>
                <p>
                  Develop strategies to improve your ESG performance and advance
                  reconciliation with Indigenous peoples.
                </p>
              </div>
              <div>
                <p>-</p>
                <p>
                  Prepare and report your sustainability plan, in alignment with
                  emerging international standards.
                </p>
              </div>
            </div>
            <div
              className={`service-text-description ${
                currentService === "decarb" ? "desc-visible" : "desc-hidden"
              }`}
            >
              <div>
                <p>-</p>
                <p>
                  Prepare a GHG inventory of Scope 1, 2, and 3 emissions, in
                  alignment with international standards such as the Task Force
                  on Climate-Related Financial Disclosures.
                </p>
              </div>
              <div>
                <p>-</p>
                <p>Set Science-Based Targets to get to Net Zero.</p>
              </div>
              <div>
                <p>-</p>
                <p>
                  Plan a decarbonization strategy to deliver on Net Zero
                  commitments, by either businesses, governments, or First
                  Nations.
                </p>
              </div>
              <div>
                <p>-</p>
                <p>
                  Develop Nature-based Solutions and carbon offset projects that
                  benefit Indigenous communities.
                </p>
              </div>
            </div>
            <div
              className={`service-text-description ${
                currentService === "impact" ? "desc-visible" : "desc-hidden"
              }`}
            >
              <div>
                <p>-</p>
                <p>
                  Assess environmental impacts of proposed projects, across a
                  range of sectors such as energy (renewables, oil and gas,
                  electricity, hydrogen, etc.), mining (particularly critical
                  minerals needed for the energy transition), infrastructure,
                  and heavy industry.
                </p>
              </div>
              <div>
                <p>-</p>
                <p>
                  Design solutions and mitigation strategies that protect the
                  environment and Indigenous rights, and deliver benefits to
                  Indigenous communities.
                </p>
              </div>
              <div>
                <p>-</p>
                <p>
                  Help Indigenous communities engage developers and governments
                  on projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
