import { useState, useRef, useEffect } from "react";
import useWindowDimensions from "./utils/useWindowDimensions";
import { scroller } from "react-scroll";
import logo from "./images/logo.svg";
import SaponukLogo from "./images/saponuk_logo.png";
import "./App.css";
import "./css/main.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
// import Projects from "./pages/Projects";

function App() {
  const [currentPage, setCurrentPage] = useState("home");
  //   const [mainPageClass]
  const [homePageClass, setHomePageClass] = useState("pageMain");
  const [aboutPageClass, setAboutPageClass] = useState("pageRight");
  const [contactPageClass, setContactPageClass] = useState("pageRight");
  const [projectsPageClass, setProjectsPageClass] = useState("pageRight");
  const theWindow = useWindowDimensions();
  const [pageTransistioning, setPageTransistioning] = useState(false);
  const currentRef = useRef("home");

  function changePage(page) {
    window.scrollTo(0, 0);
    const currentDepth = currentPage === "home" ? 0 : 1;
    if (!pageTransistioning) {
      setPageTransistioning(true);
      if (currentDepth === 0 && page !== currentPage) {
        setHomePageClass("pageLeft");
        switch (page) {
          case "about":
            setAboutPageClass("pageMain");
            setContactPageClass("pageRight");
            setProjectsPageClass("pageRight");
            break;
          case "contact":
            setContactPageClass("pageMain");
            setAboutPageClass("pageRight");
            setProjectsPageClass("pageRight");
            break;
          case "projects":
            setContactPageClass("pageRight");
            setAboutPageClass("pageRight");
            setProjectsPageClass("pageMain");
            break;
        }
      } else if (currentDepth === 1 && page !== currentPage) {
        switch (page) {
          case "home":
            setHomePageClass("pageMain");
            if (currentPage === "about") {
              setAboutPageClass("pageRight");
              break;
            } else if (currentPage === "contact") {
              setContactPageClass("pageRight");
              break;
            } else if (currentPage === "projects") {
              setProjectsPageClass("pageRight");
              break;
            }
          case "about":
            currentPage === "contact"
              ? setContactPageClass("pageLeft")
              : setProjectsPageClass("pageLeft");
            setAboutPageClass("pageMain");
            setTimeout(() => {
              currentPage === "contact"
                ? setContactPageClass("pageTransition")
                : setProjectsPageClass("pageTransition");
              //   setContactPageClass("pageTransition");
            }, 500);
            setTimeout(() => {
              currentPage === "contact"
                ? setContactPageClass("pageRight")
                : setProjectsPageClass("pageRight");
              //   setContactPageClass("pageRight");
            }, 1000);
            break;
          case "contact":
            currentPage === "about"
              ? setAboutPageClass("pageLeft")
              : setProjectsPageClass("pageLeft");
            setContactPageClass("pageMain");
            setTimeout(() => {
              currentPage === "about"
                ? setAboutPageClass("pageTransition")
                : setProjectsPageClass("pageTransition");
              //   setAboutPageClass("pageTransition");
            }, 500);
            setTimeout(() => {
              currentPage === "about"
                ? setAboutPageClass("pageRight")
                : setProjectsPageClass("pageRight");
              //   setAboutPageClass("pageRight");
            }, 1000);
            break;
          case "projects":
            console.log("test");
            currentPage === "about"
              ? setAboutPageClass("pageLeft")
              : setContactPageClass("pageLeft");
            setProjectsPageClass("pageMain");
            setTimeout(() => {
              currentPage === "about"
                ? setAboutPageClass("pageTransition")
                : setContactPageClass("pageTransition");
              //   setAboutPageClass("pageTransition");
            }, 500);
            setTimeout(() => {
              currentPage === "about"
                ? setAboutPageClass("pageRight")
                : setContactPageClass("pageRight");
              //   setAboutPageClass("pageRight");
            }, 1000);
            break;
        }
      }
      // console.log(page);
      setTimeout(() => {
        setPageTransistioning(false);
      }, 1000);
      setCurrentPage(page);
      currentRef.current = page;
    }
  }
  function handleKeyDown(e) {
    // console.log(currentRef.current);
    if (e.keyCode === 40 && currentRef.current === "home") {
      scroller.scrollTo("services");
    } else if (e.keyCode === 38 && currentRef.current === "home") {
      scroller.scrollTo("hero-image");
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
  }, []);
  return (
    <div className="App">
      <header className="header">
        <img src={SaponukLogo} className="site-logo" />
        <span>{theWindow.wind}</span>
        <nav>
          <a onClick={() => changePage("home")}>Home</a>
          <a onClick={() => changePage("about")}>About</a>
          {/* <a onClick={() => changePage("projects")}>Projects</a> */}
          <a onClick={() => changePage("contact")}>Contact</a>
        </nav>
      </header>
      {theWindow.windowType === "phone" || theWindow.windowType === "tablet" ? (
        <>
          {currentPage === "home" ? (
            <>
              <div className="pageMain">
                <Home />
              </div>
            </>
          ) : currentPage === "about" ? (
            <>
              <div className="pageMain">
                <About />
              </div>
            </>
          ) : currentPage === "projects" ? (
            <>
              <div className="pageMain">{/* <Projects /> */}</div>
            </>
          ) : (
            <>
              <div className="pageMain">
                <Contact />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className={homePageClass}>
            <Home current={currentPage} />
          </div>

          <div className={aboutPageClass}>
            <About />
          </div>

          <div className={projectsPageClass}>{/* <Projects /> */}</div>
          <div className={contactPageClass}>
            <Contact />
          </div>
        </>
      )}
      {/* )} */}
    </div>
  );
}

export default App;
